import { combineReducers } from "redux";
import { AuthReducer } from "./authReducer";
import { messagesReducer } from "./messagesReducer";
import { signalrReducer } from "./signalrReducer";
import { usersReducer } from "./usersReducer";



const rootReducer = combineReducers({
    auth: AuthReducer,
    signalr: signalrReducer,
    users: usersReducer,
    messages: messagesReducer
})

export default rootReducer;