import { HubConnection } from "@microsoft/signalr";
import { ReducerAction } from "../models/ReducerAction";
import { types } from "../types/types";



interface SignalRState {
    connection: HubConnection
}

  const initialState: SignalRState = {
    connection: null
  }



export const signalrReducer = (state: SignalRState = initialState, action: ReducerAction) => {

    switch (action.type) {

        case types.signalrConnected:
            return {
                ...state,
                connection: action.payload
            }
    
        default:
            return state;
    }

}